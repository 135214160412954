import React from "react";
import { FormGroup, Input } from "reactstrap";
import Select from 'react-select';
import Switch from "react-switch";
import CurrencyInput from 'react-currency-input-field';
import { hasContentVar, filterValueSelect } from "../../helpers/utils.js";


class FieldsGroup extends React.Component {

  render() {

    const renderLabel = () => {
      if(this.props.showLabel || (!hasContentVar(this.props.showLabel))){
        return (
          <label className="form-control-label" htmlFor={this.props.id}>
            { this.props.caption }
            { hasContentVar(this.props.tooltip) && <span title={this.props.tooltip} className="ml-1"><i className="fa fa-question-circle f-12"></i>:</span> }
          </label>
        )
      }
    }

    const renderSwitch = () => {
      switch (this.props.type) {
        case 'text':
          return (
            <Input type="text" className={"form-control-alternative " + (this.props.className || "")} id={this.props.id} name={this.props.id} value={this.props.value[this.props.id]} maxLength={this.props.maxLength} {...this.props.attr} />
          )
        case 'number':
          return (
            <Input type="number" className={"form-control-alternative " + (this.props.className || "")} id={this.props.id} name={this.props.id} value={this.props.value[this.props.id]} maxLength={this.props.maxLength} {...this.props.attr} />
          )
        case 'email':
          return (
            <Input type="email" className={"form-control-alternative " + (this.props.className || "")} id={this.props.id} name={this.props.id} value={this.props.value[this.props.id]} {...this.props.attr} />
          )
        case 'date':
          return (
            <Input type="date" className={"form-control-alternative " + (this.props.className || "")} id={this.props.id} name={this.props.id} value={this.props.value[this.props.id]} {...this.props.attr} />
          )
        case 'textarea':
          return (
            <Input type="textarea" className={"form-control-alternative " + (this.props.className || "")} id={this.props.id} name={this.props.id} value={this.props.value[this.props.id]} {...this.props.attr} />
          )
        case 'switch':
          return (
            <Switch className={"form-control-alternative height-inherit " + (this.props.className || "")} id={this.props.id} checked={this.props.value[this.props.id]} onChange={(e) => { this.props.fcHandleChange(e); }} offColor="#c9c9c9" onColor="#3bc2f1" uncheckedIcon={false} checkedIcon={false} {...this.props.attr} />
          )
        case 'file':
          return (
            <Input type="file" className={(this.props.className || "")} id={this.props.id} name={this.props.id} value={this.props.value[this.props.id]} {...this.props.attr} />
          )
        case 'select':
          return (
            <Select className={"react-select-container " + (this.props.className || "")} classNamePrefix="react-select" id={this.props.id} name={this.props.id} value={filterValueSelect(this.props.data, this.props.value[this.props.id])} getOptionLabel={(option)=>option[((hasContentVar(this.props.nameOptionSelect))?this.props.nameOptionSelect:"nombre")]} getOptionValue={(option)=>option[((hasContentVar(this.props.valueOptionSelect))?this.props.valueOptionSelect:"id")]} options={this.props.data} isSearchable={true} {...this.props.attr} />
          )
        case 'selectMulti':
          return (
            <Select className={"basic-multi-select " + (this.props.className || "")} isMulti classNamePrefix="select" id={this.props.id} name={this.props.id} value={filterValueSelect(this.props.data, this.props.value[this.props.id])} getOptionLabel={(option)=>option[((hasContentVar(this.props.nameOptionSelect))?this.props.nameOptionSelect:"nombre")]} getOptionValue={(option)=>option[((hasContentVar(this.props.valueOptionSelect))?this.props.valueOptionSelect:"id")]} options={this.props.data} isSearchable={true} {...this.props.attr} />
          )
        case 'currency':
          return (
            <CurrencyInput className={"form-control form-control-alternative " + (this.props.className || "")} id={this.props.id} name={this.props.id} value={this.props.value[this.props.id]} intlConfig={{ locale: 'es-CO', currency: 'COP' }} allowDecimals={((hasContentVar(this.props.allowDecimals))?this.props.allowDecimals:false)} decimalSeparator="." groupSeparator="," {...this.props.attr} />
          )
        default:
            return null;
      }
    }

    return (
      <FormGroup className={(this.props.withMargin || (!hasContentVar(this.props.withMargin)))?"mb-3":"mb-0"}>
        { renderLabel() }
        { renderSwitch() }
      </FormGroup>
    );
  }
}

export default FieldsGroup;
