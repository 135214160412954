import React from "react";
import { Button, Container, Row, Col } from "reactstrap";

import { toastr } from "../../helpers/utils.js";
import FieldsGroup from "components/FieldsGroup/FieldsGroup.js";

class Landing extends React.Component {

  redirect = () => {
    let valor = document.getElementById("inpNumEntrance").value;
    if(valor == null || valor.toString().length == 0){
      toastr.warn("The job number is required, please enter it!")
      return false;
    }
    window.location.href = "/timeline?job="+valor;
  }

  render() {
    return (
      <>
        <div className="position-relative">
          {/* Hero for FREE version */}
          <section className="section section-hero section-shaped h-100vh">
            {/* Background circles */}
            <div className="shape shape-style-1 shape-default">
              <span className="span-150" />
              <span className="span-50" />
              <span className="span-50 d-md-block d-none" />
              <span className="span-75 d-md-block d-none" />
              <span className="span-100 d-md-block d-none" />
              <span className="span-75 d-md-block d-none" />
              <span className="span-50" />
              <span className="span-100 d-md-block d-none" />
              <span className="span-50" />
              <span className="span-100" />
            </div>
            <Container className="shape-container d-flex align-items-center py-lg px-4">
              <div className="col px-0">
                <Row className="align-items-center justify-content-center">
                  <Col className="text-center" lg="6">
                    <img alt="LOGO" className="img-fluid img-xs-w-180" src={require("assets/img/brand/logo_firm.png")} style={{ width: "240px" }} />
                    <p className="lead text-white font-18 font-xs-17">Process management system for your workshop services, <br/> <i className="font-weight-bold">all the control in the palm of your hand.</i></p>
                    <div className="btn-wrapper mt-5">
                      <div className="box-landing">
                        <h6>Enter your <mark>Job Number</mark></h6>
                        <FieldsGroup type="number" id="inpNumEntrance" value={""} showLabel={false}/>
                        <Button className="btn-white btn-icon mb-0 color-green" color="default" size="lg" onClick={() => this.redirect()}>
                            <i className="fa fa-search mr-2 font-15" />
                            <span>View your timeline</span>
                        </Button>
                      </div>
                    </div>
                    <br/>
                    <div className="mt-3 center-div">
                      <div className="width-fit opacity-4">
                        <label className="color-white font-italic font-13">Authorized for:</label>
                        <br />
                        <img alt="LOGO" className="img-fluid" src={require("assets/img/brand/logo_serna_white.png")} style={{ width: "130px" }} />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
        </div>
      </>
    );
  }
}

export default Landing;
