import { toast } from 'react-toastify';

export const urlAPI = process.env.REACT_APP_API_URL;

export const configTokenAXI = {
    headers: {
      'Authorization': localStorage.getItem('tokenAG')
    }
}

/* ****************************** */

export const hasContentVar = (str) => {
  return (typeof str === "undefined" || str == null)?false:true;
}

export const filterValueSelect = (opts, id) => {
  if(id == "N/A"){
    return null;
  }else{
    return opts.find(o => o.id === id);
  }
}

export const changeKeysOptionsSelect = (data, nameLabel) => {
  data.forEach((element, i) => {
      if(typeof data[i][nameLabel] !== "undefined" && typeof data[i]["id"] !== "undefined"){
        Object.defineProperty(data[i], "label", Object.getOwnPropertyDescriptor(data[i], nameLabel));
        Object.defineProperty(data[i], "value", Object.getOwnPropertyDescriptor(data[i], "id"));
        delete data[i][nameLabel];
        delete data[i]["id"];
      }
  });
  return data;
}

String.prototype.toCapitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1)
}

export const currencyFormat = (num) => {
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const decimalFormat = (num) => {
  return parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const uniqueArrayObjs = (arr) => {
  let array = arr;
  return arr.filter((value, index) => {
    const _value = JSON.stringify(value);
    return index === array.findIndex(obj => { return JSON.stringify(obj) === _value });
  });
}

/* ****************************** */

export const toastr = {
  toastConfig: {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  },
  success: function(text = "Action successfully completed!") {
    toast.success('😃 '+ text, this.toastConfig);
  },
  info: function(text) {
    toast.info('🤔 '+ text, this.toastConfig);
  },
  warn: function(text) {
    toast.warn('⚠️ '+ text, this.toastConfig);
  },
  error: function(text = "Ooops, an error has occurred!") {
    toast.error('⛔ '+ text, this.toastConfig);
  },
  basic: function(text = "Action successfully completed!") {
    toast('🚀 '+ text, this.toastConfig);
  },
}