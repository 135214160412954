import React from "react";
import axios from 'axios';
import { Button, Card, Container, Row, Col } from "reactstrap";

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import { urlAPI, toastr } from "../../helpers/utils.js";
import DemoNavbar from "components/Navbars/DemoNavbar.js";

class Timeline extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      num_entrance: null,
      data: {},
      itemTimeSelect: 0, 
      listItemsTime: []
    }
  }

  //#region  *********** REQUEST ***********

  getDataTimeline = () => {
    if(!this.state.num_entrance){
      toastr.warn("The job number is required, please enter it!")
      return false;
    }
    
    axios.get(`${urlAPI}/workshop/getTimeline/${this.state.num_entrance}`)
    .then(response => {
        if(response.data.data[0][0]?.num_entrance == undefined){
          toastr.warn("This job number does not exist, please verify!");
          return false;
        }

        this.setState({ 
          data: response.data.data[0][0],
          listItemsTime: response.data.data[1]
        });
    }).catch(error => {
        let res = (typeof error.response === "undefined")?"Error accessing the server":error.response.data.message;
        toastr.error(res);
    });
  }

  //#endregion

  getListItemsTime(){
    return this.state.listItemsTime.map(e=>e.fecha);
  }

  getLabelItemTime(index){
    let item = this.state.listItemsTime[index];
    return (item)?(item.fechaFormat + " " + item.state):"";
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;

    /* *************** */
    const queryParameters = new URLSearchParams(window.location.search);
    const job = parseInt(queryParameters.get("job"));
    if(!job) window.location.href = "/";
    
    this.setState({ num_entrance: job }, () => {
      this.getDataTimeline();
    });
  }

  render() {
    return (
      <>
        <DemoNavbar />
        <main className="profile-page" ref="main">
          <section className="section-profile-cover section-shaped my-0">
            {/* Circles background */}
            <div className="shape shape-style-1 shape-default alpha-4">
              <span className="d-md-block d-none"/>
              <span className="span-75"/>
              <span />
              <span />
              <span />
              <span className="d-md-block d-none"/>
              <span className="span-200"/>
            </div>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section">
            <Container>
              <Card className="card-profile shadow mt--300">
                <div className="px-4">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image">
                        <div>
                          <img alt="LOGO SERNA" src={require("assets/img/brand/logo_serna.png")} />
                        </div>
                      </div>
                    </Col>
                    <Col className="order-lg-3 text-lg-right align-self-lg-center" lg="4">
                      <div className="py-4 mt-lg-0 float-right" style={{height: "84px"}}>
                        {/*<Button color="info" href="#pablo" onClick={(e) => e.preventDefault()} size="sm">
                          Contact Us
                        </Button>*/}
                      </div>
                    </Col>
                    <Col className="order-lg-1" lg="4">
                      <div className="card-profile-stats d-flex justify-content-center">
                        <div>
                          <span className="heading">{this.state.listItemsTime.length}</span>
                          <span className="description">Transited States</span>
                        </div>
                        <div>
                          <span className="heading">{this.state.data.days_elapsed || 0}</span>
                          <span className="description">Days Elapsed</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center mt-sm-5 mt-xs-10">
                    <span className="font-weight-light">Customer Name</span>
                    <h3 className="mb-4">{this.state.data.customer_name}</h3>
                    <div className="h6 m-auto px-sm-6 w-75 mb-xs-30">
                      Timeline of the process of your vehicle in our workshop, you can be sure of the status in real time.
                    </div>
                  </div>
                  <div className="container px-2">
                    <Row>
                      <Col sm="4">
                        <Card body className="my-sm-4 my-xs-10 py-3 center-div" style={{minHeight: "100px"}}>
                          <Row>
                            <Col xs="3" className="center-div pr-1">
                              <img alt="GARAGE" className="w-100" src={require("assets/img/icons/garage.png")} />
                            </Col>
                            <Col xs="9">
                              <h6 className="font-14 font-weight-bold mb-1">Workshop Name</h6>
                              <label className="font-13 m-0">{this.state.data.sucursal}</label>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col sm="4">
                        <Card body className="my-sm-4 my-xs-10 py-3 center-div" style={{minHeight: "100px"}}>
                          <Row>
                            <Col xs="3" className="center-div pr-1">
                              <img alt="VEHICLE" className="w-100" src={require("assets/img/icons/car.png")} />
                            </Col>
                            <Col xs="9">
                              <h6 className="font-14 font-weight-bold mb-1">Vehicle Info.</h6>
                              <label className="font-15 m-0">{this.state.data.vehicle} {this.state.data.year_vehicle}</label>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col sm="4">
                        <Card body className="my-sm-4 my-xs-10 py-3 center-div" style={{minHeight: "100px"}}>
                          <Row>
                            <Col xs="3" className="center-div pr-1">
                              <img alt="SCHEDULE" className="w-100" src={require("assets/img/icons/schedule.png")} />
                            </Col>
                            <Col xs="9">
                              <h6 className="font-14 font-weight-bold mb-1">Dates</h6>
                              <div className="font-12">
                                <label className="font-13 m-0 font-italic font-weight-bold"><span className="text-muted">Entrance: </span>{this.state.data.date_entrance}</label>
                                <br />
                                <label className="font-13 m-0 font-italic font-weight-bold"><span className="text-muted">Delivery: </span>{this.state.data.date_delivery}</label>
                              </div>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                  <div className="pb-5 pt-0 px-2 text-center">

                    <hr className="my-3 mb-4 pb-3 opacity-5"/>
                    
                    <h6 className="font-22 font-xs-20 border font-weight-light m-0 opacity-8 pb-2 text-muted bg-gray-2 shadow-sm py-3">Timeline of service status</h6>

                    <div id="divTimeline">
                      <VerticalTimeline>
                        {
                          this.state.listItemsTime.map((item, i) => {
                            return (
                              <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                date={item.fecha}
                                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                icon={(<i className='fa fa-check-square-o'></i>)}
                                key={i}
                              >
                                <label>Service status changed to:</label>
                                <h3 className="vertical-timeline-element-title">{item.state}</h3>
                                <h4 className="vertical-timeline-element-subtitle">{item.fechaFormat}</h4>
                              </VerticalTimelineElement>
                            )
                          })
                        }
                        <VerticalTimelineElement
                          iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                          icon={(<i className='fa fa-car'></i>)}
                        />
                      </VerticalTimeline>
                    </div>

                  </div>
                </div>
              </Card>
            </Container>
          </section>
        </main>
      </>
    );
  }
}

export default Timeline;
