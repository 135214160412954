import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";
import 'react-toastify/dist/ReactToastify.css';
import "assets/scss/app.scss";

import Index from "views/Index.js";
import Login from "views/examples/Login.js";
import Timeline from "views/Timeline/Timeline.js";
import Register from "views/examples/Register.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Index />} />
        {/*<Route path="/login" exact element={<Login />} />
        <Route path="/register" exact element={<Register />} />*/}
        <Route path="/timeline" exact element={<Timeline />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
    
    <ToastContainer />
  </>
);
